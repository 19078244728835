import { render, staticRenderFns } from "./Reserva.vue?vue&type=template&id=1fb84482&scoped=true&"
import script from "./Reserva.vue?vue&type=script&lang=ts&"
export * from "./Reserva.vue?vue&type=script&lang=ts&"
import style0 from "./Reserva.vue?vue&type=style&index=0&id=1fb84482&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb84482",
  null
  
)

export default component.exports