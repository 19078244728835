




























































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import VueRecaptcha from "vue-recaptcha";
import { jsPDF } from "jspdf";

@Component({
  components: { VueRecaptcha }
})
export default class Reserva extends Mixins(FormValidator) {
  private codReserva = "000000001";
  private reserva: any = {};
  private validRut = false;
  private codigo = "";
  private formattedFecha = "";
  private formattedRut = "";
  private reservaUsuario: any = {
    rut: "",
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    telefono: "",
    email: "",
    fecha: "",
    fecha_reserva: "",
    estado: "",
    tipo: "",
    bloque: "",
    codigo: "",
    foto: "",
    foto_mobile: "",
    id_evento: ""
  };
  private canvas: any = null;
  private img = new Image();

  private mounted() {
    if (
      this.$router.currentRoute.params.codigo &&
      this.$router.currentRoute.params.codigo != ""
    ) {
      this.codigo = this.$router.currentRoute.params.codigo;
      this.getReservaEvento();
    } else {
      this.routerGo("Error");
    }
  }
  private getReservaEvento() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/reserva/ticket?codigo=" + this.codigo)
      .then((res: any) => {
        this.reservaUsuario = res.data;
        this.formatRut(this.reservaUsuario.rut);
        const base = process.env.VUE_APP_BASE_URL;
        if (this.reservaUsuario.foto) {
          this.reservaUsuario.foto = base + this.reservaUsuario.foto;
        } else {
          this.reservaUsuario.foto = require("@/assets/banner_desktop.png");
        }
        if (this.reservaUsuario.foto_mobile) {
          this.reservaUsuario.foto_mobile =
            base + this.reservaUsuario.foto_mobile;
        } else {
          this.reservaUsuario.foto_mobile = require("@/assets/banner_mobile.png");
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.routerGo("Error");
      });
  }
  private printImage() {
    this.canvas = document.getElementById("myCanvas") as any;
    if (this.reservaUsuario.id_evento == 1) {
      this.img.src = require("@/assets/banner_desktop.png");
    } else {
      this.img.src = require("@/assets/banner_desktop.png");
    }
    // this.img.crossOrigin = "anonymous";
    this.img.onload = () => {
      if (this.canvas) {
        let addY = 150;
        let imgHeight = 380;
        if (this.reservaUsuario.id_evento == 1) {
          imgHeight = 250;
          addY = 0;
        }
        this.canvas.width = 1157;
        this.canvas.height = 1637;
        const ctx = this.canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(this.img, 0, 0, 1157, imgHeight);
        ctx.textAlign = "start";
        // To change the color on the rectangle, just manipulate the context
        ctx.strokeStyle = "#5F6A6A";
        ctx.fillStyle = "white";
        this.roundRect(ctx, 49, 300 + addY, 1060, 570, 20, true, true);

        ctx.textAlign = "center";
        ctx.fillStyle = "#5F6A6A";
        ctx.font = "bold 25px Verdana";
        ctx.fillText(
          "Comprobante de Reserva",
          // this.reservaUsuario.nombre_evento,
          this.canvas.width / 2 - 5,
          355 + addY
        );

        ctx.textAlign = "start";

        ctx.fillStyle = "#4E7DBB";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Reserva para: ", 120, 415 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.nombre_evento, 120, 445 + addY);

        ctx.fillStyle = "#4E7DBB";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Nombre: ", 120, 495 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(
          this.reservaUsuario.nombre +
            " " +
            this.reservaUsuario.apellido_paterno +
            " " +
            this.reservaUsuario.apellido_materno,
          120,
          525 + addY
        );
        // ctx.fillRect(120, 920 / 2, 600, 2);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Rut: ", 120, 575 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.formattedRut, 120, 605 + addY);
        // ctx.fillRect(120, 1080 / 2, 600, 2);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Tipo Reserva: ", 600, 575 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.tipo, 600, 605 + addY);
        // ctx.fillRect(120, 1240 / 2, 600, 2);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Fecha Reserva: ", 120, 655 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.fecha_reserva, 120, 685 + addY);
        // ctx.fillRect(120, 1400 / 2, 600, 2);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Horario Reserva: ", 600, 655 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.bloque, 600, 685 + addY);
        // ctx.fillRect(120, 1560 / 2, 600, 2);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Fecha Transacción: ", 120, 737 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.fecha, 120, 765 + addY);

        ctx.fillStyle = "#4E7DBB";
        ctx.fillText("Cod. Reserva: ", 600, 737 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.codigo, 600, 765 + addY);

        ctx.textAlign = "center";
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(
          "Recuerda presentar tu identificación el día de la reserva.",
          this.canvas.width / 2 - 5,
          // 980
          950 + addY
        );
        ctx.fillText("¡Te Esperamos!", this.canvas.width / 2 - 5, 980 + addY);

        let width = this.canvas.width;
        let height = this.canvas.height;
        var pdf = new jsPDF("p", "pt", [595.28, 841.89]);

        //then we get the dimensions from the 'pdf' file itself
        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();
        pdf.addImage(
          this.canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          width,
          height,
          undefined,
          "FAST"
        );
        pdf.save("Reserva.pdf");
      }
    };
  }
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private roundRect(
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill: any,
    stroke: any = true
  ) {
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }
}
